/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object, string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import ReactMarkdown from 'react-markdown';
import Media from 'react-media';
import CategoryCarouselUI from './Partials/GraphqlHPCategoryCarouselUI';
import parseStyles from '../../../../../helpers/contentstack/parseStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        overflow: 'hidden',
        textAlign: 'center',
        color: '#757575',
        fontSize: '14px',
        fontFamily: 'sans-serif',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
        width: '100%',
        '&::-webkit-scrollbar': {
            visibility: 'hidden',
        },
    },
    title: {
        width: '100%',
        color: '#212121',
        fontWeight: 600,
        '& > *': {
            fontSize: 14,
            margin: '7px auto 5px auto',
        },
    },
    tileIcon: {
        width: '75px',
        height: 'auto',
        clipPath: 'ellipse(36px 36px at 38px 41px)',
        webkitClipPath: 'ellipse(36px 36px at 38px 41px)',
    },
}));

const GraphqlHPCategoryCarousel = ({  data, ssrDeviceType }) => {
    let headerFont = data?.title_font_family;
    headerFont = parseStyles({ font_family: headerFont });
    const classes = useStyles();
    const circleOverlay = data?.show_image_circle_overlay_mobile || false;
    return (
        <Media
            query="(max-width: 767px)"
            defaultMatches={ssrDeviceType === 'mobile'}
            render={() => (
                <div className={classes.root}>
                    <div style={headerFont} className={classes.title}>
                        <ReactMarkdown
                            source={data?.markdown_title || data?.title}
                            skipHtml
                        />
                    </div>
                    <GridList className={classes.gridList} cols={2.5} cellHeight={110} aria-hidden="true" tabindex="-1">
                        {data?.carousel_group?.map((tileData) => (
                            <CategoryCarouselUI key={`${tileData?.link?.href}`} tileData={tileData} circleOverlay={circleOverlay} />
                        ))}
                    </GridList>
                </div>
            )}
        />
    );
};

GraphqlHPCategoryCarousel.propTypes = {
    data: object.isRequired,
    ssrDeviceType: string.isRequired,
};

export default GraphqlHPCategoryCarousel;
