/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, shape, string, func, bool,
} from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ResponsiveImage from '../../../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import { trackEvent as track } from '../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        overflow: 'hidden',
        backgroundColor: theme.palette.background?.paper,
        textAlign: 'center',
        color: theme.palette.colorNeutral70,
        fontSize: '14px',
        fontFamily: 'sans-serif',
    },
    listItem: {
        margin: '0 3.5%',
        textDecoration: 'none',
    },
    title: {
        color: theme.palette.common?.black,
        textDecoration: 'none',
    },
    tileIcon: {
        width: '77px',
        height: '80px',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        clipPath: 'ellipse(36px 36px at 38px 41px)',
        webkitClipPath: 'ellipse(36px 36px at 38px 41px)',
    },
    circleOverlayIcon: {
        clipPath: 'inherit',
    },
});

const GraphqlHPCategoryCarouselUI = ({
    classes, tileData, trackEvent, circleOverlay,
}) => (
    <Link
        key={tileData?.link?.href}
        to={tileData?.link?.href}
        className={classes.listItem}
        onClick={() => {
            trackEvent({
                eventCategory: tileData?.tracking_event_category || '',
                eventAction: tileData?.tracking_event_action || '',
                eventLabel: tileData?.tracking_event_label || '',
            });
        }}
    >
        <ResponsiveImage className={circleOverlay ? classes.tileIcon : `${classes.circleOverlayIcon} ${classes.tileIcon}`} path={`${tileData?.mobile_image?.url}`} params={{ mobile: 'quality=100' }} alt={tileData?.link?.title} />
        <div className={classes.title}>
            {tileData?.link?.title}
        </div>
    </Link>
);

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
});

GraphqlHPCategoryCarouselUI.propTypes = {
    tileData: shape({
        link: shape({
            href: string.isRequired,
            title: string.isRequired,
        }),
        mobile_image: shape({
            url: string.isRequired,
        }),
    }).isRequired,
    classes: object.isRequired,
    trackEvent: func.isRequired,
    circleOverlay: bool.isRequired,
};

export default connect(
    null,
    mapDispatchToProps,
)(withStyles(styles)(GraphqlHPCategoryCarouselUI));
